/*--------------------------------------------------------------
# Icons
--------------------------------------------------------------*/
.mc-arrow {
	display: block;
	height: 1rem;
	width: 1.2rem;
	@include backgroundimage(contain);
	background-image: url(#{$path-images}icons/arrow.svg);

	&--light {
		background-image: url(#{$path-images}icons/arrow-white.svg);
	}

	@include mq($bp-xs) {
		width: 1rem;
	}
}

.mc-close {
	display: block;
	height: 1rem;
	width: 1rem;
	@include backgroundimage(contain);
	background-image: url(#{$path-images}icons/close-white.svg);

	@include mq($bp-xs) {
		width: .9rem;
		height: .9rem;
	}
}

.mc-locked {
	display: inline-block;
	height: 1.6rem;
	width: 1.6rem;
	@include backgroundimage(contain);
	background-image: url(#{$path-images}icons/locked-white.png);

	@include mq($bp-m) {
		width: 1.5rem;
		height: 1.5rem;
	}

	@include mq($bp-xs) {
		width: 1.2rem;
		height: 1.2rem;
	}
}