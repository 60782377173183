/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	text-rendering: optimizeLegibility;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: default;
	@include sansprimary;
	color: $c-white;
	font-size: 1.6rem;
}

html {
	font-size: 10px;
	-webkit-text-size-adjust: 100%;
}

/*--------------------------------------------------------------
# Headings (h1 through h6)
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Each heading and paragraph style is shared by both the tag
# and a corresponding class. This allows us to use correct markup
# and override it when necessary to maintain the visual hierarchy
# established by the design team.
--------------------------------------------------------------*/
h1,
.h1 {
	font-size: 6.4rem;
	line-height: 1.25;

	@include mq($bp-l) {
		font-size: 6rem;
	}

	@include mq($bp-m) {
		font-size: 5.5rem;
	}

	@include mq($bp-s) {
		font-size: 4.5rem;
	}

	@include mq($bp-xs) {
		font-size: 4rem;
		line-height: 1.2;
	}
}

h2,
.h2 {
	font-size: 5.5rem;
	line-height: 1.25;

	@include mq($bp-l) {
		font-size: 5rem;
	}

	@include mq($bp-m) {
		font-size: 4.5rem;
	}

	@include mq($bp-s) {
		font-size: 4rem;
	}

	@include mq($bp-xs) {
		font-size: 3.5rem;
	}
}

h3,
.h3 {
	font-size: 4.5rem;
	line-height: 1.25;

	@include mq($bp-l) {
		font-size: 4rem;
	}

	@include mq($bp-m) {
		font-size: 3.5rem;
	}

	@include mq($bp-s) {
		font-size: 3rem;
	}

	@include mq($bp-xs) {
		font-size: 2.5rem;
	}
}

h4,
.h4 {
	font-size: 2.8rem;
	line-height: 1.25;
	letter-spacing: -.2px;

	@include mq($bp-l) {
		font-size: 2.6rem;
	}

	@include mq($bp-m) {
		font-size: 2.4rem;
	}

	@include mq($bp-s) {
		font-size: 2.2rem;
	}

	@include mq($bp-xs) {
		font-size: 2rem;
	}
}

h5,
.h5 {
	font-size: 2rem;
	line-height: 1;

	@include mq($bp-m) {
		font-size: 1.85rem;
	}

	@include mq($bp-s) {
		font-size: 1.75rem;
	}

	@include mq($bp-xs) {
		font-size: 1.5rem;
	}
}

h6,
.h6 {
	@include sanssecondary;
	font-size: 1.3rem;
	line-height: 1.7;
	// letter-spacing: 1.9px;
	// text-transform: uppercase;

	@include mq($bp-m) {
		font-size: 1.2rem;
	}

	@include mq($bp-s) {
		font-size: 1.1rem;
		letter-spacing: 1px;
	}

	@include mq($bp-xs) {
		font-size: 1rem;
	}
}

/*--------------------------------------------------------------
# Paragraphs / Body Copy
--------------------------------------------------------------*/
p,
.paragraph {
	@include sanssecondary;
	font-size: 1.8rem;
	line-height: 1.7;

	@include mq($bp-l) {
		font-size: 1.7rem;
	}

	@include mq($bp-m) {
		font-size: 1.6rem;
	}

	@include mq($bp-s) {
		font-size: 1.5rem;
	}

	@include mq($bp-xs) {
		font-size: 1.45rem;
	}
}

small,
.small {
	font-size: 1.3rem;
	line-height: 1;

	@include mq($bp-xs) {
		font-size: 1.2rem;
	}
}

/*--------------------------------------------------------------
# Blockquote
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
	a {
		color: $c-secondary-dark;
		// mix-blend-mode: hard-light;
	}
}

/*--------------------------------------------------------------
# Eyebrows
--------------------------------------------------------------*/
.mc-eyebrow {
	margin-bottom: 1.5rem;

	@include mq($bp-m) {
		margin-bottom: 1rem;
	}

	@include mq($bp-s) {
		margin-bottom: .5rem;
	}
}

/*--------------------------------------------------------------
# Selection
--------------------------------------------------------------*/
::selection {
	background: $c-secondary-light;
}
