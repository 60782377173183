/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.mc-footer {
	margin-top: auto;
	@include spacing;
	@include paddingtb(5rem, 6rem);
	text-align: center;

	@include mq($bp-s) {
		@include paddingtb(3rem, 4rem);
	}
}
