/*--------------------------------------------------------------
# Colors
--------------------------------------------------------------*/
// basic
$c-black: #161616;
$c-white: #ffffff;

// custom
$c-primary: #0323e0;
$c-primary-medium: #333dff;
$c-primary-light: #dde2ff;
$c-secondary: #ffeeb3;
$c-secondary-darker: #F8C927;
$c-secondary-dark: #FCD54F;
$c-secondary-light: #FFF8E1;

// other
$red: #e51518;

/*--------------------------------------------------------------
# Media Queries / Breakpoints
--------------------------------------------------------------*/
$bp-xxs: 360px;
$bp-xs: 520px;
$bp-sxs: 620px;
$bp-s: 790px;
$bp-sm: 860px;
$bp-m: 1024px;
$bp-xm: 1160px;
$bp-l: 1280px;
$bp-xl: 1350px;
$bp-default: 1440px;
$bp-xxl: 1680px;

/*--------------------------------------------------------------
# Z-index
--------------------------------------------------------------*/
$z-index-modal: 100;
$z-index-loader: 150;

/*--------------------------------------------------------------
# Paths
--------------------------------------------------------------*/
$path-images: '/dist/images/';
