/*--------------------------------------------------------------
# Font Mixins
--------------------------------------------------------------*/
@mixin sansprimary($weight: regular) {
  font-family: 'Belleza', 'Helvetica', sans-serif;

  @if $weight == regular {
    font-weight: 400;
  }
}

@mixin sanssecondary($weight: regular) {
font-family: 'Open Sans', 'Helvetica', sans-serif;

  @if $weight == regular {
    font-weight: 400;
  }
}