/*--------------------------------------------------------------
# Media queries
--------------------------------------------------------------*/
@mixin mq($breakpoint, $min-max: max-width) {
	@media all and ($min-max: $breakpoint){
		@content
	}
}

/*--------------------------------------------------------------
# Flexbox
--------------------------------------------------------------*/
@mixin flexbox($align: center, $justify: space-between, $direction: row, $wrap: wrap, $display: flex) {
	display: $display;
	flex-direction: $direction;
	align-items: $align;
	flex-wrap: $wrap;
	justify-content: $justify;
}

/*--------------------------------------------------------------
# Position Absolute / Position Fixed
--------------------------------------------------------------*/
@mixin position($top: 0, $right: $top, $bottom: $top, $left: $top, $position: absolute) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin absolutelycentered() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/*--------------------------------------------------------------
# Padding
--------------------------------------------------------------*/
@mixin paddingtb($value, $value2: $value) {
	padding-top: $value;
	padding-bottom: $value2;
}

@mixin paddinglr($value, $value2: $value) {
	padding-left: $value;
	padding-right: $value2;
}


/*--------------------------------------------------------------
# margin
--------------------------------------------------------------*/
@mixin margintb($value, $value2: $value) {
	margin-top: $value;
	margin-bottom: $value2;
}

@mixin marginlr($value, $value2: $value) {
	margin-left: $value;
	margin-right: $value2;
}

/*--------------------------------------------------------------
# Square
--------------------------------------------------------------*/
@mixin square($value) {
	position: relative;
	width: $value;
	overflow: hidden;

	&:before{
		content: "";
		display: block;
		padding-top: 100%;
	}
}

/*--------------------------------------------------------------
# Background Image
--------------------------------------------------------------*/
@mixin backgroundimage($size: cover, $position: center center, $repeat: no-repeat) {
	background-size: $size;
	background-repeat: $repeat;
	background-position: $position;
}

/*--------------------------------------------------------------
# Site max-width
--------------------------------------------------------------*/
@mixin maxwidth($value) {
	max-width: $value;
	margin-left: auto;
	margin-right: auto;
}

/*--------------------------------------------------------------
# This module generates left and right padding responsively
# There are different sizes representing small to large gutters
# This allows us to update left/right margins/gutters sitewide easily
# And makes sure content across different modules and templates aligns
--------------------------------------------------------------*/
@mixin spacing($size: regular) {
	@if $size == regular {
		@include paddinglr(6.5rem);

		@include mq($bp-l) {
			@include paddinglr(6rem);
		}

		@include mq($bp-m) {
			@include paddinglr(5.5rem);
		}
	}

	@include mq($bp-s) {
		@include paddinglr(4.5rem);
	}

	@include mq($bp-xs) {
		@include paddinglr(3rem);
	}
}

/*--------------------------------------------------------------
# Transition
--------------------------------------------------------------*/
@mixin transition($target: all, $time: .175s, $type: ease-in-out, $delay: 0) {
	transition: all $time $type;
	transition-property: $target;
	transition-delay: $delay;
}


/*--------------------------------------------------------------
# Square
--------------------------------------------------------------*/
@mixin square($value) {
	position: relative;
	width: $value;
	overflow: hidden;

	&:before{
		content: "";
		display: block;
		padding-top: 100%;
	}
}
