/*--------------------------------------------------------------
# Home
--------------------------------------------------------------*/
.mc-site-content {
	@include spacing;
	@include margintb(auto);
	@include maxwidth(100rem);
	width: 80%;
	text-align: center;

	@include mq($bp-s) {
		width: 90%;
	}

	@include mq($bp-xs) {
		width: 95%;
	}

	.mc-heading {
		@extend .h2;
		@include maxwidth(13em);
		margin-bottom: 5rem;

		@include mq($bp-l) {
			margin-bottom: 4.5rem;
		}

		@include mq($bp-m) {
			margin-bottom: 4rem;
		}

		@include mq($bp-s) {
			margin-bottom: 3.5rem;
		}

		@include mq($bp-xs) {
			margin-bottom: 3rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&__link {
			position: relative;
			white-space: nowrap;

			&--info {
				display: inline-block;
				position: absolute;
				width: 0;
				height: 1em;

				.content {
					@extend .small;
					line-height: 1;
					display: block;
					position: absolute;
					top: 120%;
					left: 0;

					@include mq($bp-m) {
						top: 115%;
					}

					@include mq($bp-xs) {
						top: 110%;
					}
				}
			}
		}
	}
}
