/*--------------------------------------------------------------
# Body / HTML
--------------------------------------------------------------*/
body,
html{
	width: 100%;
	height: 100%;
}

html {
	background-color: $c-primary;
}

body {
	position: relative;
	&, * {
		cursor: none !important;
	}
}

/* When scroll is locked, this class gets applied to the body */
.locked-scroll {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  height: 100%;
}

.mc-site-wrapper {
	@include flexbox(stretch, flex-start, column);
	min-height: 100vh;
	position: relative;
	z-index: 1;
	// max-width: 120rem;
	// text-align: center;

	& > * {
		width: 100%;
	}
}
