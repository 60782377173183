/*--------------------------------------------------------------
# CTAs
--------------------------------------------------------------*/
.mc-cta {
	@include paddingtb(1rem);
	@include margintb(-1rem);
	display: inline-block;
	transform: translateX(4%);

	&--light {
		color: $c-white;

		.mc-arrow {
			@extend .mc-arrow--light;
		}
	}

	&--small {
		@extend .h5;
		line-height: 1;
	}

	&:not(&--small) {
		@extend .h4;
		line-height: 1;
	}

	&__icon,
	&__title {
		display: inline-block;
		vertical-align: middle;
	}

	&__icon {
		margin-top: .2em;
		mix-blend-mode: hard-light;
	}
}
