/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.mc-cursor {
	display: inline-block;
	width: 13rem;
	height: 13rem;
	position: fixed;
	pointer-events: none;
	z-index: 0;

	&--disabled &__circle {
		background: #ddd !important;
	}

	&--modal {
		z-index: $z-index-modal + 1;
		mix-blend-mode: difference;
	}

	&--modal &__circle {
		background: $c-white !important;
		z-index: $z-index-modal + 1;

		.no-mix-blend-mode & {
			background: #666 !important;
			opacity: .2;
			cursor: pointer;
		}
	}

	&__circle {
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background: darken($c-primary, 25%);
		border-radius: 50%;

		@include mq($bp-s) {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
