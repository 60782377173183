/*--------------------------------------------------------------
# Modals
--------------------------------------------------------------*/
.mc-modal {
	background: $c-black;
	color: $c-white;
	@include paddingtb(6rem, 6.5rem);
	@include spacing;
	@include position(2rem, $position: fixed);
	bottom: auto;
	text-align: center;
	z-index: $z-index-modal;
	opacity: 0;
	visibility: hidden;

	@include mq($bp-s) {
		@include paddingtb(5.5rem, 6rem);
	}

	@include mq($bp-xs) {
		@include paddingtb(4rem, 4.5rem);
		top: 1.5rem;
		left: 1.5rem;
		right: 1.5rem;
	}

	@include mq($bp-xxs) {
		@include paddingtb(3rem, 4rem);
	}

	::selection {
		background: #888;
	}

	&__content,
	&__close {
		z-index: $z-index-modal + 1;
	}

	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		padding: 1rem;

		@include mq($bp-xs) {
			top: .5rem;
			right: .5rem;
		}
	}

	&__title {
		margin-bottom: 2.5rem;
		display: inline-block;

		@include mq($bp-s) {
			margin-bottom: 2rem;
		}

		@include mq($bp-xs) {
			margin-bottom: 1.5rem;
		}

		.mc-locked {
			display: block;
			@include marginlr(auto);
			margin-bottom: 1rem;
		}
	}

	&__ctas {
		& > * {
			margin-right: 2rem;
			// display: block;
			// margin: 0 auto;

			&:last-child {
				margin-right: 0;
			}

			@include mq($bp-s) {
				margin-right: 1.5rem;
			}

			// @include mq($bp-xs) {
			// 	margin-right: 1rem;
			// }

			@include mq($bp-xs) {
				display: block;
				margin-right: 0;
				margin-left: .5rem;
			}
		}
	}
}

.mc-modal-overlay {
	@include position($position: fixed);
	display: none;
}