/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.mc-header {
	@include spacing;
	@include paddingtb(5rem, 6rem);
	text-align: center;

	@include mq($bp-s) {
		@include paddingtb(3rem, 4rem);
	}

	&__logo {
		display: inline-block;
		width: 60px;
		height: 60px;
		@include backgroundimage(contain);
		background-image: url(#{$path-images}clausse_logo.svg);

		@include mq($bp-m) {
			width: 40px;
			height: 40px;
		}
	}
}
