/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Hidden Text
--------------------------------------------------------------*/
.hidden-text {
  position: absolute;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  font-size: 0;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  clip: rect(1px,1px,1px,1px);
}